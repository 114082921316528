.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 화면 전체를 차지하는 컨테이너 */
.center-container {
  display: flex;
  justify-content: center; /* 가로로 가운데 정렬 */
  align-items: center;     /* 세로로 가운데 정렬 */
  height: 100vh;           /* 화면 전체 높이 */
}

/* 버튼에 대한 스타일 */
.center-button {
  width: 40px;       /* 버튼의 너비를 40px로 설정 */
  height: 40px;      /* 버튼의 높이를 40px로 설정 */
  font-size: 12px;   /* 버튼 텍스트 크기 조정 */
  padding: 0;        /* 기본 패딩 제거 */
  text-align: center;/* 텍스트 중앙 정렬 */
}
